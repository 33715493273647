import axios from 'axios';

export default class SituacionFinanciera {
    getSituacionFinanciera() {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/situacionfinanciera`;
		const url = `/api/situacionfinanciera`;
		return axios.get(url).then(res=>res.data.data);
	}

    createSituacionFinanciera(situacionfinanciera){
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/situacionfinanciera`;
		const url = `/api/situacionfinanciera`;
		return axios.post(url,situacionfinanciera).then(res=>res.data.data);
	}

    getSituacionFinancieraID(situacionfinanciera){
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/situacionfinanciera/${situacionfinanciera}`;
		const url = `/api/situacionfinanciera/${situacionfinanciera}`;
		return axios.get(url,situacionfinanciera).then(res=>res.data.data);
	}
    getSituacionFinancieraExport(situacionfinanciera){
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/situacionfinanciera/${situacionfinanciera.fechainicio}/${situacionfinanciera.fechafin}`;
		const url = `/api/situacionfinanciera/${situacionfinanciera.fechainicio}/${situacionfinanciera.fechafin}`;
		return axios.get(url,situacionfinanciera).then(res=>res.data.data);
	}

	updatesituacionfin(pago) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/situacionfinanciera/${pago.id_pago}`;
		const url = `/api/situacionfinanciera/${pago.id_pago}`;
		return axios.put(url,pago).then(res=>res.data.data);
	}

	getSituacionFinancieraExportNew(situacionfinanciera){
		//const url = `${API_URL}/api/estados`;
		//const url = ` https://sam.apicontrol.website/api/api/situacionfinancieraexcel/${situacionfinanciera.fechainicio}/${situacionfinanciera.fechafin}`;
		const url = `/api/situacionfinancieraexcel/${situacionfinanciera.fechainicio}/${situacionfinanciera.fechafin}`;
		return axios.get(url,situacionfinanciera).then(res=>res.data.data);
	}

}