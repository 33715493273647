<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<!-- <Button label="Nuevo Pais..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" /> -->
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedPaises || !selectedPaises.length" /> -->
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="openNewExport" />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="financiera" v-model:selection="selectedConsulta" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords}">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Situación Financiera</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['nombredoctor']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="nombredoctor" header="Doctor" :sortable="true"></Column>
					<Column field="id_muestra" header="Muestra" :sortable="true"></Column>
					<Column field="pacienteN" header="Paciente" :sortable="true"></Column>
                    <Column field="fecha_solicitud" header="Fecha Solicitud a SGCells" :sortable="true">
						<template #body="slotProps">
							<div  >
								{{computedDateMX(slotProps.data.fecha_solicitud)}}
							</div>
						</template>
					</Column>
					<Column field="estatu" header="Estatus" :sortable="true">	</Column>
					<Column field="situacion" header="Situación Financiera" :sortable="true"></Column>
					<Column field="nombrevendedor" header="Vendedor" :sortable="true"></Column>
					<Column field="pago" header="Pago" :sortable="true">	
						<template #body="slotProps">
							<div :class="preciostyle" style="text-align: right">
								${{formatCurrency(slotProps.data.pago)}}
							</div>
						</template>
					</Column>
					<Column field="descuento" header="Descuento Aplicado" :sortable="true">	
						<template #body="slotProps">
							<div :class="preciostyle" style="text-align: right">
								${{formatCurrency(slotProps.data.descuento)}}
							</div>
						</template>
					</Column>
					<Column field="saldo" header="Saldo" :sortable="true">
						<template #body="slotProps">
							<div :class="preciostyle" style="text-align: right">
								${{formatCurrency(slotProps.data.saldo)}}
							</div>
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="editConsulta(slotProps.data)" />
							<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePais(slotProps.data)" /> -->
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="ConsultaDialog" :style="{width: '950px'}" header="Situación Financiera" :modal="true" class="p-fluid">
					<div class="p-field">
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Doctor: {{dataConsulta.nombredoctor}}</label>	
								 <hr width=100% style="border-width:2px 0 0 0;margin:0">							 
							</div>						 
						</div>
					</div>
                    <div class="p-field">
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Muestra:</label> <label for="clave" style="font-weight:700">{{dataConsulta.id_muestra}}</label>								 
							</div>
							<div class="p-field p-col">
								<label for="contrasena">Paciente: {{dataConsulta.pacienteN}}</label>								 
							</div>
							<div class="p-field p-col">
								<label for="contrasena">Fecha de Solicitud : {{computedDateMX(dataConsulta.fecha_solicitud)}}</label>								 
							</div>
							<div class="p-field p-col" v-show="dataConsulta.id_usuario_autoriza">
								<label for="contrasena">Descuento Aplicado : {{dataConsulta.porc_descuento + '%'}}</label>								 
							</div>
							<div class="p-field p-col">
								<label for="contrasena">Situación Financiera :</label> <label for="contrasena" style="font-weight:700">{{dataConsulta.situacion}}</label>								 
							</div>
						</div>
					</div>
					<div class="p-field" style="height:500px">
						<div class="p-formgrid p-grid">
							<DataTable ref="dt" :value="ServiciosSelect"   dataKey="nombre" :paginator="false">
								<Column field="servicio" header="Servicio" :sortable="true"></Column>
								<Column field="precio" header="Precio" :sortable="true">
									<template #body="slotProps">
										<div :class="preciostyle" style="text-align: right">
											${{formatCurrency(slotProps.data.precio)}}
										</div>
									</template>
								</Column>									 
							</DataTable>
							
						</div>
							<div style="text-align: right;">							
							<label v-if="visibleT" >Abono :  ${{formatCurrency(Totals - descuento - dataConsulta.saldo)}}</label>	
							<label v-else>Abono :  ${{formatCurrency(TotalN)}}</label>
						</div>
						<div style="text-align: right;" v-show="descuento > 0">							
							<label v-if="visibleT" >Descuento :  ${{formatCurrency(descuento)}}</label>	
							<label v-else>Descuento :  ${{formatCurrency(descuento)}}</label>
						</div>
						<div style="text-align: right;">
							<label  v-if="visibleT" >Saldo :  ${{formatCurrency(dataConsulta.saldo)}}</label>	
							<label 	v-else>Saldo :  ${{formatCurrency(Totals- descuento -TotalN)}}</label>
						</div>		
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="Fecha">Fecha *</label>
								<Calendar dateFormat="dd/mm/yy" id="basic" :showIcon="true" v-model="Fecha" :class="{'p-invalid': submitted && !Fecha}"></Calendar>
								<small class="p-invalid" v-if="submitted && !Fecha">La Fecha es requerida</small>							 
							</div>
							<div class="p-field p-col">
								<label for="Fecha">Monto *</label>
								<InputNumber mode="currency"  currency="USD" locale="en-US"  id="pais" v-model="Monto" required="true" autofocus :class="{'p-invalid': submitted && !Monto}" />
								<small class="p-invalid" v-if="submitted && !Monto">El monto es requerido</small>							 
							</div>
							<div class="p-field p-col">
								<label for="Fecha">Método de Pago *</label>
								<InputText id="pais" v-model="MetodoPago" required="true" autofocus :class="{'p-invalid': submitted && !MetodoPago}" />
								<small class="p-invalid" v-if="submitted && !Banco">El metodo de pago es requerido</small>
							</div>
							<div class="p-field p-col">
								<label for="Fecha">Banco</label>
								<InputText id="pais" v-model="Banco" required="true" autofocus />								 
							</div>
							<div class="p-field p-col-9">
								<label for="Fecha">Observación</label>
								<Textarea maxlength="500" id="pais" v-model="Observacion" autofocus style="resize:none;height:34px;"/>
							</div>
							<div class="p-field p-col-3">
								 <Button style="margin-top:25px" label="Agregar Pago" @click="AgregarPago" icon="pi pi-check" class="p-button-raised " :disabled="dataConsulta.id_estatus == 4"  />			 
							</div>
							<div style="max-width: 100%; overflow-x: auto;">
							<DataTable ref="dt" :value="PagosMuestra"   dataKey="nombre" :paginator="false" tableStyle="min-width: 50rem" :autoLayout="true">
								<Column field="fecha_pago" header="Fecha" :sortable="true">
									<template #body="slotProps">
										<div  style="white-space:nowrap">
											{{computedDateMX(slotProps.data.fecha_pago)}}
										</div>
									</template>
								</Column>
								<Column field="anterior" header="Saldo Anterior" :sortable="true">
									<template #body="slotProps">
										<div :class="preciostyle" style="text-align: right">
											${{formatCurrency(slotProps.data.anterior)}}
										</div>
									</template>
								</Column>		
								<Column field="monto" header="Pago" :sortable="true">
									<template #body="slotProps">
										<div :class="preciostyle" style="text-align: right">
											${{formatCurrency(slotProps.data.monto)}}
										</div>
									</template>
								</Column>		
								<Column field="actual" header="Saldo Actual" :sortable="true">
									<template #body="slotProps">
										<div :class="preciostyle" style="text-align: right">
											${{formatCurrency(slotProps.data.actual)}}
										</div>
									</template>
								</Column>	
								<Column field="metodo_pago" header="Método de Pago" :sortable="true"></Column>
								<Column field="banco" header="Banco" :sortable="true"></Column>	
								<Column field="observacion" header="Observación" :sortable="true" headerStyle="max-width:470px"></Column>	
								<Column>
									<template #body="slotProps">									 
										<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDelete(slotProps.data)" />
									</template>
								</Column>							 
							</DataTable>
							</div>
						</div>				
					</div>		
				
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/> 
					</template>
				</Dialog>
				
				<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span >¿Desea eliminar el registro</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="delesituacionFin" />
					</template>
				</Dialog>


				<Dialog v-model:visible="ExportExcel" :style="{width: '850px'}" header="Exportar a Excel" :modal="true" class="p-fluid">
			
					<div class="p-grid">
					
						<div class="p-col-6">
							<label for="Fecha">Fecha Inicio*</label>
							<Calendar dateFormat="dd/mm/yy" id="basic"  :showIcon="true"  v-model="FechaInicio" :class="{'p-invalid': submitted && !FechaInicio}"></Calendar>
							<small class="p-invalid" v-if="submitted && !FechaInicio">La FechaInicio es requerida</small>		
						</div>	
						<div class="p-col-6">
							<label for="Fecha">Fecha Fin*</label>
							<Calendar dateFormat="dd/mm/yy"  id="basic" :showIcon="true"  v-model="FechaFin" :class="{'p-invalid': submitted && !FechaFin}"></Calendar>
							<small class="p-invalid" v-if="submitted && !Fecha">La FechaFin es requerida</small>	
						</div>	
						<div class="p-col-6" style="margin-top:40%">
							 
						</div>
						 
					</div>
					
				
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialogExport"/> 
						<Button label="Exportar" icon="pi pi-check" class="p-button-text" @click="download" />
					</template>
				</Dialog>

			
			</div>
		</div>
	</div>

</template>

<script>
import SituacionFinanciera from '../service/SituacionFinanciera';
import XLSX from 'xlsx'
import Cookies from "js-cookie"
window.Cookies = Cookies
 import moment from 'moment';

export default {
	data() {
		return {			
			ConsultaDialog: false,
			ExportExcel:false,
			deletePaisDialog: false,
			deletePaisesDialog: false,
		 	selectedConsulta: null,
			filters: {},
			submitted: false,
			visibleT:true,			 
			financiera:null,
		 	Totals:0,
			descuento:0,
			dataConsulta:null,
			ServiciosSelect:null,
			ServiciosM:null, 
			Fecha:null,
			Monto:null,
			MetodoPago:null,
			Banco:null,
			Observacion:null,
			PagoPost:[],
			useconver:null,
			userdata:null,
			PagosMuestra:null,
			FechaFin:null,
			FechaInicio:null,
			PostExport:[],
			FinancieraExpor:[],
			deleteDialog:false,
			TotalN:0,
			detallepago:null,
			UpdEnviarPago:[],
			PagoFinan:null,
			SaldoExcel:null,
			MontoExcel:null,
			PrecioExcel:null,
			IdMuestraExce:null,
			Active:null,
		}
	},
	situacionfinanciera: null,
	created() {
		this.situacionfinanciera = new SituacionFinanciera();
	},
	mounted() {
		this.situacionfinanciera.getSituacionFinanciera().then(data => {
			this.financiera = data.muestras;
			this.ServiciosM = data.servicios;
			 
			for(var i in this.financiera)
			{
				
				var Nombre=this.financiera[i].paciente;
                this.financiera[i].pacienteN=Nombre+" "+data.muestras[i].pacienteApP+" "+data.muestras[i].pacienteApM;
                	
				if(this.financiera[i].id_estatus ==1 || this.financiera[i].id_estatus==2)
				{
					this.financiera[i].situacion='Al Corriente';
					for(var ir1 in data.pagoservicio)
					{
						if(this.financiera[i].id_muestra == data.pagoservicio[ir1].id_muestra)
						{
							var servtotal1= data.pagoservicio[ir1].monto;
							var descuento1 = this.financiera[i].id_usuario_autoriza ? Number(this.financiera[i].porc_descuento)/100 * servtotal1 : 0;

							var finan1=0;


							this.financiera[i].descuento = descuento1;
							this.financiera[i].pago=finan1;
							this.financiera[i].saldo=servtotal1 - descuento1 - finan1;			

							// if(data.pagofinan.length ==0)
							// {
							// 	this.financiera[i].pago=finan1;
							// 	this.financiera[i].saldo=servtotal1-finan1;	

							// }else
							// {							
								for(var ij1 in data.pagofinan)
								{
									finan1=0;
									finan1=data.pagofinan[ij1].monto;
									
									if(this.financiera[i].id_muestra == data.pagofinan[ij1].id_muestra)
									{									
										this.financiera[i].pago=finan1;
										this.financiera[i].saldo=servtotal1 - descuento1 - finan1;
										
										if(servtotal1 - descuento1 == finan1)
										{
											this.financiera[i].situacion='Pagado';
										}										
																	 
									} 	
									// else
									// {										
									// 	this.financiera[i].pago=0;
									// 	this.financiera[i].saldo=servtotal1;
										
									// }								 
										
								// }
							}
						}
					}

				}
				else
				{			
					for(var ir in data.pagoservicio)
						{
							if(this.financiera[i].id_muestra == data.pagoservicio[ir].id_muestra)
							{
								var servtotal=data.pagoservicio[ir].monto;
								var descuento = this.financiera[i].id_usuario_autoriza ? Number(this.financiera[i].porc_descuento)/100 * servtotal : 0;
								console.log('descuento', data.porc_descuento ,descuento1)

								var finan = 0;

								this.financiera[i].descuento = descuento;
								this.financiera[i].pago=finan;
								this.financiera[i].saldo = servtotal - descuento - finan;

								if(data.pagofinan.length == 0)
								{
									this.financiera[i].pago = finan;
									this.financiera[i].saldo=servtotal - descuento - finan;	
									this.financiera[i].situacion = 'Vencido';

								}else
								{
									for(var ij in data.pagofinan)
									{
										if(this.financiera[i].id_muestra == data.pagofinan[ij].id_muestra)
										{
											finan=data.pagofinan[ij].monto;
											// this.financiera[i].pago=finan;

											if(Number(servtotal) - descuento == Number(finan))
											{
												this.financiera[i].situacion='Pagado';
											}else
											{
												this.financiera[i].situacion='Vencido';
											}
										}else
										{
											this.financiera[i].situacion='Vencido';
										}
										this.financiera[i].pago = finan;
										this.financiera[i].saldo = servtotal - descuento - finan;
									}
								}
							}
						}

					}								 
				}
			});

		var today = new Date();
		 
		this.Fecha=this.computedDatesFormatNow(today);		

	},
	methods: {
		openNew() {
			this.pais = {};
			this.submitted = false;
			this.paisDialog = true;
			this.pais.activo=true;
			this.PagoPost=[];
				this.Fecha=null;
				this.MetodoPago=null;
				this.Banco=null;
				this.Monto=null;
				this.Observacion=null;
		},
		hideDialog() {
			this.ConsultaDialog = false;
			this.submitted = false;
			this.Totals=0;
			this.descuento = 0;
			this.dataConsulta=null;
			this.ServiciosSelect=[];
			document.location.reload();
		},
		openNewExport() {
			 
			this.ExportExcel = true;
			this.FinancieraExpor=[];
			this.FechaInicio=null;
			this.FechaFin=null;
			 
		},
		confirmDelete(deleteFin){
			this.detallepago=deleteFin;
			this.deleteDialog = true;
		},
		hideDialogExport() {
			this.ExportExcel = false;
			this.FinancieraExpor=[];
			 
		},
		editConsulta(ConsultaDoc) {
			 	
			this.Totals=0;
			this.dataConsulta=null;
			this.ServiciosSelect=[];
			this.dataConsulta = ConsultaDoc; 
			console.log(ConsultaDoc)
			 this.ConsultaDialog = true;
			for(var i in this.ServiciosM){				
				if(this.dataConsulta.id_muestra == this.ServiciosM[i].id_muestra){

					this.Totals=this.Totals+Number(this.ServiciosM[i].precio);

					this.ServiciosSelect.push(this.ServiciosM[i]);
				}
			}
			this.descuento = this.dataConsulta.descuento;
			this.Actualizargrid();	
			this.visibleT=true; 
		 
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},		
		download : function() {
			if(this.FechaInicio != null && this.FechaFin != null)
			{
				this.PostExport=[];
				this.PostExport.push({
					"fechainicio":this.computedDatesFormatSaveExpr(this.FechaInicio),
					"fechafin":this.computedDatesFormatSaveExpr(this.FechaFin)
				});
				this.situacionfinanciera.getSituacionFinancieraExportNew(this.PostExport[0]).then(data => {
					// this.FinancieraExpor = data
					for(var i in data.muestra)
					{
						for(var ij in this.financiera)
						{
							if(data.muestra[i].id_muestra == this.financiera[ij].id_muestra)
							{
								this.Active=true;
								const dataFilter = data.pagos.find(element => element[0].id_muestra == data.muestra[i].id_muestra);
								// console.log(dataFilter);
								if(dataFilter === undefined){
									this.Active=false;		
								}
								for(var j in data.pagos){
									 
										// this.FinancieraExpor.push({
										// 				'Doctor':this.financiera[ij].nombredoctor,
										// 				'Muestra':this.financiera[ij].id_muestra,
										// 				'Paciente':this.financiera[ij].pacienteN,
										// 				'Fecha Solicitud a SGCells':this.financiera[ij].fecha_solicitud,									 
										// 				'Estatus':this.financiera[ij].estatu,
										// 				'Situación Financiera':this.financiera[ij].situacion,
										// 				'Precio':this.PrecioExcel,
										// 				'Fecha Pago':'',
										// 				'Pago':'',
										// 				'Método de Pago':'',
										// 				'Banco':'',
										// 				'Saldo':this.PrecioExcel,
										// 				// 'Saldo':this.financiera[ij].saldo,
													
										// 			})	;	
								 
											if(data.pagos[j][0] != undefined){
										if(data.muestra[i].id_muestra == data.pagos[j][0].id_muestra){
											
											for(var g in data.pagosservicio){
												if(data.pagosservicio[g].length != 0){
													if(data.muestra[i].id_muestra == data.pagosservicio[g][0].id_muestra){
													this.PrecioExcel= data.pagosservicio[g][0].monto;
													}
												}	
												
											}
											this.SaldoExcel=0;
											// this.PrecioExcel= this.financiera[ij].pago;
											if(data.pagos[j].length > 0)
											{
												for(var h in data.pagos[j]){
													this.MontoExcel= data.pagos[j][h].monto; 
													if(this.SaldoExcel==0){													
														this.SaldoExcel=this.PrecioExcel-this.MontoExcel;
													}else{
														this.SaldoExcel=this.SaldoExcel-this.MontoExcel;
													}
												
													this.FinancieraExpor.push({
														'Doctor':this.financiera[ij].nombredoctor,
														'Muestra':this.financiera[ij].id_muestra,
														'Paciente':this.financiera[ij].pacienteN,
														'Fecha Solicitud a SGCells':this.computedDateMX(this.financiera[ij].fecha_solicitud),									 
														'Estatus':this.financiera[ij].estatu,
														'Situación Financiera':this.financiera[ij].situacion,
														'Vendedor':this.financiera[ij].nombrevendedor,
														'Precio':Number(this.PrecioExcel),
														'Porcentaje descuento': this.financiera[ij].id_usuario_autoriza? this.financiera[ij].porc_descuento:0,
														'Descuento': this.financiera[ij].descuento,
														'Fecha Pago':this.computedDateMX(data.pagos[j][h].fecha_pago),
														'Pago':Number(data.pagos[j][h].monto),
														'Método de Pago':data.pagos[j][h].metodo_pago,
														'Banco':data.pagos[j][h].banco,
														'Observacion':data.pagos[j][h].observacion,
														'Saldo':Number(this.SaldoExcel) - this.financiera[ij].descuento,
														// 'Saldo':this.financiera[ij].saldo,
													
													})	;	
												}
											
											}
										}	
																								 								
									 
									}
								
									 	
								}	
								if(!this.Active){
									for(var g1 in data.pagosservicio){
										if(data.pagosservicio[g1].length != 0){
												if(data.muestra[i].id_muestra == data.pagosservicio[g1][0].id_muestra){
													this.PrecioExcel= data.pagosservicio[g1][0].monto;
												}
											}	
										}
									this.FinancieraExpor.push({
														'Doctor':this.financiera[ij].nombredoctor,
														'Muestra':this.financiera[ij].id_muestra,
														'Paciente':this.financiera[ij].pacienteN,
														'Fecha Solicitud a SGCells':this.computedDateMX(this.financiera[ij].fecha_solicitud),									 
														'Estatus':this.financiera[ij].estatu,
														'Situación Financiera':this.financiera[ij].situacion,
														'Vendedor':this.financiera[ij].nombrevendedor,
														'Precio':Number(this.PrecioExcel),
														'Porcentaje descuento': this.financiera[ij].id_usuario_autoriza? this.financiera[ij].porc_descuento:0,
														'Descuento': this.financiera[ij].descuento,
														'Fecha Pago':'',
														'Pago':'',
														'Método de Pago':'',
														'Banco':'',
														'Observacion':'',
														'Saldo':Number(this.PrecioExcel) - this.financiera[ij].descuento,
														// 'Saldo':this.financiera[ij].saldo,
													
													})	;	
								}	    			 		
							}
						}
					}
						let NameExport="SituacionFinanciera_"+this.computedDateMX(this.FechaInicio)+" al "+this.computedDateMX(this.FechaFin)+".xlsx";
						if(this.FinancieraExpor != null)
						{
							const data = XLSX.utils.json_to_sheet(this.FinancieraExpor)
							const wb = XLSX.utils.book_new()
							XLSX.utils.book_append_sheet(wb, data, 'data')
							XLSX.writeFile(wb,NameExport)
						}
					});
					this.hideDialogExport();
			}
			
		},
		 formatCurrency(value) {
            // return value.toLocaleString('en-US', {style: 'currency',currency: 'USD'});
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
		AgregarPago(){
			this.useconver = Cookies.get("user");

			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}
			this.PagoPost = [];
			this.PagoPost.push({
				"id_muestra":this.dataConsulta.id_muestra,
				"fecha_pago":this.computedDatesFormatSave(this.Fecha),
				"metodo_pago":this.MetodoPago,
				"banco":this.Banco,
				"monto":this.Monto,
				"observacion":this.Observacion,
				"id_usuario":this.userdata[0].id_usuario,
			});

			if(this.Fecha != null && this.MetodoPago != null && this.Monto != null){
				this.situacionfinanciera.createSituacionFinanciera(this.PagoPost[0]).then(data =>{
					console.log(data);
					this.situacionfinanciera.getSituacionFinancieraID(this.dataConsulta.id_muestra).then(data => {
						
					this.PagosMuestra=data.data1;
					var Total =this.Totals - this.descuento;

					var TotalM=0;
						
					for(var i in this.PagosMuestra){	
						
						TotalM=TotalM+(Number(this.PagosMuestra[i].monto));
						
						if(i =='0')
						{
							this.PagosMuestra[i].anterior=Total;
							this.PagosMuestra[i].actual=Total-this.PagosMuestra[i].monto;
							
						}
						else
						{
							this.PagosMuestra[i].anterior=this.PagosMuestra[Number(i)-1].actual;
							this.PagosMuestra[i].actual=this.PagosMuestra[i].anterior-this.PagosMuestra[i].monto;
						}
					}	
					this.TotalN=TotalM;
					this.visibleT=false; 
			});
				});
				this.PagoPost=[];
				this.submitted = false;
				this.Fecha=null;
				this.MetodoPago=null;
				this.Banco=null;
				this.Monto=null;
				this.Observacion=null;
				this.Actualizargrid();
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'Se ha agregado el pago', life: 3000});
					
			}else{
				this.submitted = true;
				this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'Hay campos por llenar. Favor de revisar', life: 3000});
			}
			

		},
		computedDatesFormatSave(value) {
                return moment(value).format('YYYY/MM/DD')
		},
		Actualizargrid(){
			this.situacionfinanciera.getSituacionFinancieraID(this.dataConsulta.id_muestra).then(data => {
				this.PagosMuestra=data.data1;
				var Total =this.Totals - this.descuento;
					 
				for(var i in this.PagosMuestra){	
					 
					 
					 if(i =='0')
					 {
						this.PagosMuestra[i].anterior=Total;
						this.PagosMuestra[i].actual=Total-this.PagosMuestra[i].monto;
						
					}
					else
					{
						this.PagosMuestra[i].anterior=this.PagosMuestra[Number(i)-1].actual;
						this.PagosMuestra[i].actual=this.PagosMuestra[i].anterior-this.PagosMuestra[i].monto;
					}
						
						 
					 	
				 	
				}	
			});
		},
		computedDatesFormatSaveExpr(value) {
                return moment(value).format('YYYY-MM-DD')
		},

		computedDatesFormatNow(value) {
                return moment(value).format('MM/DD/YYYY')
		},
		computedDateMX(value) {
                return moment(value).format('DD-MM-YYYY')
		},
		delesituacionFin(){
			console.log(this.detallepago);
			this.UpdEnviarPago.push({
						"id_pago":this.detallepago.id_pago,						 
						"activo":0,
					});

					console.log(this.UpdEnviarPago);

			this.situacionfinanciera.updatesituacionfin(this.UpdEnviarPago[0]).then(data =>{
					console.log(data);
					 this.Actualizargrid();
					 this.UpdEnviarPago=[];
			});
			this.Actualizargrid();
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Precio eliminada', life: 3000});
			this.deleteDialog = false;


				this.situacionfinanciera.getSituacionFinancieraID(this.dataConsulta.id_muestra).then(data => {
						
					this.PagosMuestra=data.data1;
					var Total =this.Totals - this.descuento;

					var TotalM=0;
						
					for(var i in this.PagosMuestra){	
						
						TotalM=TotalM+(Number(this.PagosMuestra[i].monto));
						
						if(i =='0')
						{
							this.PagosMuestra[i].anterior=Total;
							this.PagosMuestra[i].actual=Total-this.PagosMuestra[i].monto;
							
						}
						else
						{
							this.PagosMuestra[i].anterior=this.PagosMuestra[Number(i)-1].actual;
							this.PagosMuestra[i].actual=this.PagosMuestra[i].anterior-this.PagosMuestra[i].monto;
						}
					}	
					this.TotalN=TotalM;
					this.visibleT=false; 
			});
			 
		},
		
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pais-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .pais-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pais-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}

.columna-observacion {
  width: 50%;
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
  overflow-x: auto;
}

.tabla-pago{
	table-layout: auto;
}
</style>
